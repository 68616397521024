<template>
  <div>
    <a-card title="资金流水详情">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section id="pdfDom">
        <a-spin :spinning="loading">
          <a-descriptions bordered>
            <a-descriptions-item label="创建人">
              {{ info.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ info.create_time }}
            </a-descriptions-item>
            <a-descriptions-item label="结算账户名称">
              {{ info.account_name }}
            </a-descriptions-item>
            <a-descriptions-item label="结算账户编号">
              {{ info.account_number }}
            </a-descriptions-item>
            <a-descriptions-item label="流水类型">
              {{ info.type_display }}
            </a-descriptions-item>

            <a-descriptions-item v-if="info.type === 'purchase'" label="采购合同">
              <a @click="jumpPurchaseOrderDetail(info)">
                {{ info.purchase_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_purchase'" label="作废采购合同">
              <a @click="jumpPurchaseOrderDetail(info)">
                {{ info.void_purchase_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'purchase_return'" label="采购退货单号">
              <a @click="jumpPurchaseReturnOrderDetail(info)">
                {{ info.purchase_return_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_purchase_return'" label="作废采购退货单号">
              <a @click="jumpPurchaseReturnOrderDetail(info)">
                {{ info.void_purchase_return_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'sales'" label="销售单号">
              <a @click="jumpSalesOrderDetail(info)">
                {{ info.sales_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_sales'" label="作废销售单号">
              <a @click="jumpSalesOrderDetail(info)">
                {{ info.void_sales_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'sales_return'" label="销售退货单号">
              <a @click="jumpSalesReturnOrderDetail(info)">
                {{ info.sales_return_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_sales_return'" label="作废销售退货单号">
              <a @click="jumpSalesReturnOrderDetail(info)">
                {{ info.void_sales_return_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'payment'" label="付款单据">
              <a @click="jumpPaymentOrderDetail(info)">
                {{ info.payment_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_payment'" label="作废付款单据">
              <a @click="jumpPaymentOrderDetail(info)">
                {{ info.void_payment_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'collection'" label="收款单据">
              <a @click="jumpCollectionOrderDetail(info)">
                {{ info.collection_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_collection'" label="作废收款单据">
              <a @click="jumpCollectionOrderDetail(info)">
                {{ info.void_collection_order_number }}
              </a>
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'charge'" label="收支单据">
                {{ info.charge_order_number }}
            </a-descriptions-item>
            <a-descriptions-item v-if="info.type === 'void_charge'" label="作废收支单据">
                {{ info.void_charge_order_number }}
            </a-descriptions-item>
            <a-descriptions-item label="变化之前">
              {{ info.amount_before }}
            </a-descriptions-item>
            <a-descriptions-item label="变化金额">
              {{ info.amount_change }}
            </a-descriptions-item>
            <a-descriptions-item label="变化之后">
              {{ info.amount_after }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      info: {},
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.info = JSON.parse(this.$route.query.item);
    },
    jumpPurchaseOrderDetail(item) {
      this.$router.push({ path: "/purchasing/purchase_record_detail", query: { id: item.purchase_order } });
    },
    jumpPurchaseReturnOrderDetail(item) {
      this.$router.push({ path: "/purchasing/return_record_detail", query: { id: item.purchase_return_order } });
    },
    jumpSalesOrderDetail(item) {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: item.sales_order } });
    },
    jumpSalesReturnOrderDetail(item) {
      this.$router.push({ path: "/sale/sale_return_detail", query: { id: item.sales_return_order } });
    },
    jumpPaymentOrderDetail(item) {
      this.$router.push({ path: "/finance/payment_detail", query: { id: item.payment_order } });
    },
    jumpCollectionOrderDetail(item) {
      this.$router.push({ path: "/finance/collection_detail", query: { id: item.collection_order } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
